<template>
  <div class="bg">
    <h1>Tom and Jerry (Video Chat - Connection and Data)</h1>
    <div class="ml-3 mt-3">
      User Id: {{ yourId }}<br/>
      <div class="mb-3">
        <button class="btn btn-secondary mr-3" @click="setUser('tom', 'jerry')">Tom</button>
        <button class="btn btn-secondary" @click="setUser('jerry', 'tom')">Jerry</button>
      </div>
      Initiator: {{ initiator }}<br/>
      Offer: {{ offer ? "Yes" : "No" }}<br/>
      Answer: {{ answer ? "Yes" : "No" }}<br/>
      Data sent: {{ messageSent }}<br/>
      Data received: {{ messageReceived }}
    </div>
    <div class="mb-3">
      <button class="btn btn-primary mr-3" @click="sendOffer">Send Offer</button>
      <button class="btn btn-primary" @click="sendAnswer">Send Answer</button>
    </div>
    <button class="btn btn-primary" @click="clearFirestore">Clear Firestore</button>
    <h1>Log</h1>
    <div>
      Generated ({{ outgoing.length }})
      <div v-for="(m, index) in outgoing" v-bind:key="'o_' + index">
        {{ m }}
      </div>
    </div>
    <br/>
    <div>
      Received ({{ received.length }})
      <div v-for="(m, index) in received" v-bind:key="'o_' + index">
        {{ m }}
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '@/services/db';
import { getLog } from "@/services/log";
import Peer from "simple-peer";
let log = getLog('test');

export default {
  name: 'app',
  data() {
    return {
      p: null,
      yourId: "tom",
      otherId: "jerry",
      initiator: false,
      roomName: "room1",

      // Message
      messageSent: "",
      messageReceived: "",

      offer: null,
      answer: null,

      // Debug
      outgoing: [], 
      received: []     
    }
  },
  mounted() {
  },
  methods: {
    setUser(name, othername) {
      this.yourId = name;
      this.otherId = othername;
      log.log("userid=", this.yourId);
    },
    sendOffer() {
      this.initiator = true;
      this.init();
    },
    sendAnswer() {
      this.initiator = false;
      this.init();
    },
    init() {
      log.log("init");

      let p = new Peer({
        initiator: this.initiator,
        trickle: true,
      });

      p.on('error', err => log.log('error', err));

      p.on('signal', data => {
        log.log('SIGNAL', data)
        this.outgoing.push(data);
        /*
        * Saving signal in Tom and Jerry format
        */
        if (data.type == "offer") {
          this.offer == data;
          db.collection(this.otherId).doc("sdp").set(data);
        } else if (data.type == "answer") {
          this.answer == data;
          db.collection(this.otherId).doc("sdp").set(data);
        } else if (data.candidate) {
          data = data.candidate;
          data.sdp = data.candidate;
          delete data.candidate;
          db.collection(`${this.otherId}/candidate/candidates`).add(data);
        }
      });

      p.on('connect', () => {
        this.messageSent = 'whatever' + Math.random();
        log.log('CONNECT', this.messageSent);
        p.send(this.messageSent);
      });

      p.on('data', data => {
        this.messageReceived = data;
        log.log('data: ' + data)
      });

      this.p = p;

      // On message on firebase
      db.collection(this.yourId).doc("sdp").onSnapshot((doc) => {
        let sdp = doc.data();
        log.log("sdp signal: ", sdp);
        if (sdp) {
          this.received.push(sdp);
          this.p.signal(sdp);
        }
      });
      db.collection(`${this.yourId}/candidate/candidates`).onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type == "added") {
            let doc = change.doc;
            let candidate = doc.data();
            if (candidate) {
              candidate.candidate = candidate.sdp;
              delete candidate.sdp;
              candidate = {candidate: candidate};
              log.log("candidate signal: ", candidate);
              this.received.push(candidate);
              this.p.signal(candidate);
            }
          }
        });
      });
    },
    clearFirestore() {
      let batch = db.batch();
      var count = 0;
      batch.delete(db.collection(this.yourId).doc("sdp"));
      batch.delete(db.collection(this.otherId).doc("sdp"));
      let p1 = db.collection(`${this.yourId}/candidate/candidates`).get().then((qs) => {
        qs.forEach((msg) => {
          batch.delete(msg.ref);
          count += 1;
        });
      });
      let p2 = db.collection(`${this.otherId}/candidate/candidates`).get().then((qs) => {
        qs.forEach((msg) => {
          batch.delete(msg.ref);
          count += 1;
        });
      });
      Promise.all([p1,p2]).then(() => {
        log.log(`deleted ${count} items`);
        batch.commit();
        this.received = [];
        this.outgoing = [];
      })
    }
  }
}
</script>

<style scoped>

.bg {
  background-color: bisque;
}

textarea {
  width: 600px;
  height: 600px;
}

</style>
